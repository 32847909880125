<template>
  <section id="smart_element">
    <div class="grey-box"></div>
    <div class="container template smart_element">
      <div class="row">
        <div class="col-md-5 col">
          <h2 data-aos="fade-right">
            Корпоративный портал
            <span class="sky">SMART</span> Element
          </h2>

          <div class="description" data-aos="fade-left">
            Ваш информационный HR инструмент, внутренний сайт компании, который
            объединяет все информационные ресурсы в одной системе
          </div>

          <img
            src="@/assets/images/smart_element.svg"
            style="margin: 60px 0;"
            width="100%"
            data-aos="fade-right"
          />
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-5 vertical-carousel-div" data-aos="fade-left">
          <div class="arrow arrow-up" @click="arrow(true)">
            <img src="@/assets/images/arrow.svg" />
          </div>
          <div class="carousel_arrows">
            <div class="dots">
              <div
                :class="{ active: scrollAmount <= 0 }"
                @click="scrollAmount = 0"
              ></div>
              <div
                :class="{ active: scrollAmount === 660 }"
                @click="scrollAmount = 660"
              ></div>
              <div
                :class="{ active: scrollAmount >= 1320 }"
                @click="scrollAmount = 1320"
              ></div>
            </div>
          </div>

          <div class="vertical-carousel" id="carousel">
            <div
              class="feature"
              v-for="(item, index) in smart_element"
              :key="'carousel-feature' + index"
              :style="[index === 0 ? 'margin-top: 0' : '']"
              @mouseenter="item.hovered = true"
              @mouseleave="item.hovered = false"
              :id="'carousel-feature-' + index"
            >
              <img
                src="@/assets/images/feature_lines.svg"
                v-if="item.hovered"
              />
              <div class="title">
                <div class="line"></div>
                <h4>{{ item.title }}</h4>
              </div>
              <div class="description">
                {{ item.description }}
              </div>
            </div>
          </div>

          <div class="arrow arrow-down" @click="arrow()">
            <img src="@/assets/images/arrow.svg" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.timeout =
      window.innerWidth >= 768
        ? setTimeout(() => {
            this.scrollAmount += 660
          }, 10000)
        : null
  },

  methods: {
    arrow(up) {
      let amount = up ? -660 : 660
      this.scrollAmount = this.scrollAmount + amount
      this.setScrollInterval()
    },

    setScrollInterval() {
      if (this.timeout) clearInterval(this.timeout)
      this.timeout = setInterval(() => {
        this.scrollAmount += 660
      }, 10000)
    },
  },

  watch: {
    scrollAmount(top) {
      if (this.timeout) clearTimeout(this.timeout)

      if (top < 0) {
        this.scrollAmount = 1320
      } else if (top > 1320) {
        this.scrollAmount = 0
      }

      document.getElementById('carousel').scrollTo({ top, behavior: 'smooth' })
      this.timeout = setTimeout(() => {
        this.scrollAmount += 660
      }, 5000)
    },
  },

  data: () => ({
    window: window,
    scrollAmount: 0,
    timeout: null,

    smart_element: [
      {
        title: 'Новости',
        description: 'Публикация новостей, комментарии и оценки',
        hovered: false,
      },
      {
        title: 'Календарь и списки дел',
        description: 'Ведение и отображение задач и корпоративных событий',
        hovered: false,
      },
      {
        title: 'Библиотека',
        description: 'Хранение документации, категоризация, доступы',
        hovered: false,
      },

      {
        title: 'Структура',
        description: 'Организационная структура, должности, филиальная сеть',
        hovered: false,
      },
      {
        title: 'Опросы',
        description: 'Создание опросов для работников',
        hovered: false,
      },
      {
        title: 'Тесты',
        description: 'Создание и проведение тестов для пользователей',
        hovered: false,
      },

      {
        title: 'Контрагенты',
        description: 'Справочники клиентов, контрагентов',
        hovered: false,
      },
      {
        title: 'Языки',
        description: 'Многоязычность системы',
        hovered: false,
      },
    ],
  }),
}
</script>
