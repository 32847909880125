<template>
  <section id="crew">
    <div class="grey-box"></div>
    <div class="container template crew">
      <div class="row">
        <div class="col-md-5 col" style="margin-bottom: 60px">
          <h2>Команда SMART <span class="sky">STATE</span></h2>
          <div class="description">
            Это опытные и квалифицированные проект-менеджеры, разработчики и
            аналитики. Наша деятельность ориентирована на положительный
            результат проекта. Мы оценим ваш проект, сделаем анализ, предложим
            варианты разработки на базе наших продуктов или предложим
            альтернативные варианты решений, которые будут более выгодны вам.
          </div>
          <button class="button">
            <a class="link-help" :href="'mailto:' + person.email"
              >Получить консультацию</a
            >
            <div class="line"></div>
          </button>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-5">
          <div class="advantages">
            <div
              class="item"
              v-for="(item, index) in advantages"
              :key="item.title"
              :class="{ active: item.active }"
            >
              <h4 @click="choseAdv(index)">
                {{ item.title }}
              </h4>
              <b-collapse
                class="description"
                :id="'adv-secription-' + index"
                v-model="item.active"
              >
                {{ item.description }}
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    choseAdv(index) {
      for (let item of this.advantages) {
        item.active = false
      }

      this.advantages[index].active = true
    },
    // showModal() {
    //   document.getElementById('modal-cb').style.display = 'unset'
    //   document.getElementById('focName').focus()
    // },
  },

  data: () => ({
    person: {
      email: 'erlan.b@mail.ru',
    },
    advantages: [
      {
        title: 'Скорость внедрения разработки',
        description:
          'Наши готовые продукты внедряются в максимально короткие сроки. Процесс разработки индивидуальных модулей занимает объективное количество времени и прогнозируется в период обсуждения проекта.',
        active: true,
      },
      {
        title: 'Цены ниже рыночных',
        description:
          'Стоимость продуктов существенно ниже рыночных аналогов и зависит от приобретаемого количества лицензий. Свяжитесь с нами и убедитесь лично!',
        active: false,
      },
      {
        title: 'Сертифицированный продукт',
        description:
          'Система SMART ELEMENT сертифицирована в Министрестве Юстиции РК и может быть поставлена на баланс вашей организации.',
        active: false,
      },
    ],
  }),
}
</script>
