<template>
    <section id="products">
        <img
                src="@/assets/images/lines_element_blue.svg"
                class="products-lines"
                data-aos="fade"
        />

        <div class="container template products">
            <div class="row">
                <div class="col">
                    <h2 data-aos="fade-right">
                        Наши продукты
                    </h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col">
                    <div class="description" data-aos="fade-left">
                        <span class="sky">SMART </span>
                        <span>
                                ELEMENT - универсальная web платформенная
                                корпоративная система, предназначенная для
                                интеграции всех бизнес-процессов организации.
                            </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                        class="col-md-4 product"
                        v-for="(item, index) in products"
                        :key="'products' + index"
                        data-aos="fade-down"
                        :data-aos-delay="100 * (index + 1)"

                >
                    <div class="item"
                         :style="{ 'background-image': `url(${require('@/assets/images/products/' + index + '.png')})` }">
                        <h3 v-html="item.title"></h3>
                        <div class="predesc">
                            АИС SMART ELEMENT
                        </div>
                        <div class="content">
                            {{ item.description }}
                        </div>
                        <a class="link" href="#" @click.prevent="navTo(item.to)">
                            <div class="line"></div>
                            <div class="text">подробнее</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
    export default {
        methods: {
            navTo(item){
                document.getElementById(item).scrollIntoView({ behavior: "smooth" })
            }
        },

        data: ()=>({
            products: [
                {
                    title: "Документооборот <span class='sky'>Task</span> Flow",
                    description:
                        "Эффективный инструмент по настройке маршрутов движения, согласования и исполнения задач, связанных с оборотом внутренних документов вашей организации",
                    to: "task_flow"
                },

                {
                    title: "Корпоративный портал",
                    description:
                        "WEB платформа, позволяющая хранить документацию, делиться новостями и взаимодействовать со всеми работниками организации",
                    to: "smart_element"
                },

                {
                    title: "Автоматизация и разработки",
                    description:
                        "Разработки любой сложности для автоматизации ваших бизнес-процессов, учета и отчетности, интеграции информационных потоков с другими системами организации",
                    to: "automation"
                },
            ],
        })
    }
</script>