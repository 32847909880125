<template>
    <div class="container" style="min-height: 100px; padding-top: 30px;">
        <div class="row mt-auto">
            <div class="col-xs-6"> <img class="footer-logo" src="@/assets/images/logo-dark.png" alt="SMARTSTATE">
            </div>
            <div class="col-xs-6 ml-auto text-right">
                <p class="margin-b-0">
                    <a class="fweight-700" href="http://smartstate.kz/" style="font-weight: 700!important;">ТОО SMART STATE</a><br>
                    Designed by: <a class="fweight-700" href="http://smartstate.kz/" style="font-weight: 700!important;">smartstate.kz</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppFooter"
}
</script>

<style scoped>
a{
    color: #81848f;
}
a:hover{
    text-decoration: none;
    color: #0a4d55;
}
</style>