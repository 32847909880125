import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

Vue.config.productionTip = false;

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import AOS from "aos";
import "aos/dist/aos.css";

const yandex_settings = {
    apiKey: '88fbe37c-d500-41d0-b8e5-40902a03bcd2',
    lang: 'ru_RU'
};
import YmapPlugin from 'vue-yandex-maps'

Vue.use(YmapPlugin, yandex_settings)

new Vue({
    created() {
        AOS.init();
    },
    render: h => h(App)
}).$mount("#app");
