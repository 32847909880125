<template>
    <div class="modal-cb" id="modal-cb" @click="handleBackdropClick">
        <div class="back-modal"></div>
        <div class="block-modal">
            <div class="container modal-body text-center">
                <h4 class="mt-2">
                    Оставьте свои данные, наши менеджера свяжутся с вами.
                </h4>
                <div class="mt-5 ml-auto mr-auto" style="position:relative; width: 50%;">
                    <input type="text" id="focName" class="input-tel" placeholder="Имя" v-model="name">
                    <span class="focus-border focus-border-name"></span>
                </div>

                <div class="mt-3 ml-auto mr-auto" style="position:relative; width: 50%">
                    <input type="tel" class="input-tel" placeholder="+7(7xx)xxx-xx-xx" v-mask="'+7(7##)###-##-##'"
                           v-model="telNum">
                    <span class="focus-border focus-border-tel"></span>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-darksky" @click="sendRequest">Оставить заявку</button>
            </div>
        </div>
    </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/v-mask/dist/v-mask.min.js"></script>
<script>
export default {
    name: "CallBackModal",
    data: function () {
        return {
            telNum: "",
            name: ""
        }
    },
    methods: {
        sendRequest(){
            this.hide();
        },
        hide() {
            this.telNum = "";
            this.name = "";
            document.getElementById('modal-cb').style.display = "none";
        },
        handleBackdropClick(e){
            if (e.path[0].className === "back-modal") {
                this.hide();
            }
        }
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>