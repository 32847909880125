<template>
    <section id="automation">
        <div class="container template automation">
            <div class="card-columns">
                <div class="card">
                    <h2 data-aos="fade-right">
                        Автоматизация и разработки
                    </h2>

                    <div class="description" data-aos="fade-left">
                        На базе нашей системы мы можем разработать
                        индивидуальные модули любой сложности для
                        автоматизации бизнес-процессов Вашей организации
                        независимо от сферы деятельности. А также
                        осуществляем разработки по отдельным проектам,
                        разработки сайтов и мобильных приложений.
                    </div>
                </div>

                <div
                        v-for="(item, index) in automatic_features"
                        :key="item.title"
                        class="card"
                >
                    <div
                            class="automatic_feature"
                            @mouseenter="item.hovered = true"
                            @mouseleave="item.hovered = false"
                            :data-aos="
                                index % 2 === 0 ? 'fade-right' : 'fade-left'
                            "
                            :style="{
                                background: `linear-gradient(
                                        258.53deg,
                                        rgba(230, 230, 230, 0.5) 3.13%,
                                        rgba(10, 77, 85, 0.5) 97.11%
                                    ),
                                    url(${require('@/assets/images/automatic_features/' +
                                        index +
                                        '.png')})`,
                            }"
                    >
                        <img
                                src="@/assets/images/feature_lines.svg"
                                v-if="item.hovered"
                        />
                        <div class="title">
                            <div class="line"></div>
                            <h4>{{ item.title }}</h4>
                        </div>
                        <div class="description">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data: ()=>({
            automatic_features: [
                {
                    title: "Объекты",
                    description:
                        "Справочники транспорта, недвижимости и других объектов учета",
                    hovered: false,
                },
                {
                    title: "Контрагенты",
                    description:
                        "Справочники клиентов, контрагентов и их атрибуты",
                    hovered: false,
                },
                {
                    title: "Личный кабинет",
                    description:
                        "Система уведомлений и исполнения поступающих задач",
                    hovered: false,
                },
                {
                    title: "Бюджеты",
                    description:
                        "Разработка форм бюджетов, расчета финансовых результатов и рентабельности",
                    hovered: false,
                },
                {
                    title: "Аналитика",
                    description:
                        "Разработка WEB аналитики, онлайн таблиц и графиков",
                    hovered: false,
                },
                {
                    title: "Отчетность",
                    description: "Разработка отчетности и выгрузки",
                    hovered: false,
                },
                {
                    title: "Сайты",
                    description:
                        "Сайты любой сложности, интеграции с внутренними системами компании",
                    hovered: false,
                },
                {
                    title: "Мобильные приложения",
                    description:
                        "Androind и iOS, интеграции с внутрнними системами компании",
                    hovered: false,
                },
                {
                    title: "API",
                    description:
                        "Готовый метод API для интеграций, онлайн интеграция с 1С",
                    hovered: false,
                },
            ],
        })
    }
</script>