<template>
    <div id="app">
        <welcome/>
        <products/>
        <task-flow/>
        <smart-element/>
        <automation/>
        <crew/>
        <contacts/>
        <yandex-map :coords="[43.209723, 76.900747]" :zoom="16" style="height: 500px; width: 100%">
            <ymap-marker
                :coords="[43.209723, 76.900747]"
                marker-id="123"
                hint-content="some hint"
            />
        </yandex-map>
        <app-footer/>
        <call-back-modal/>
    </div>
</template>

<script>
import Welcome from "./components/Welcome";
import Products from "./components/Products";
import TaskFlow from "./components/TaskFlow";
import SmartElement from "./components/SmartElement";
import Automation from "./components/Automation";
import Crew from "./components/Crew";
import Contacts from "./components/Contacts";
import AppFooter from "./components/AppFooter";
import CallBackModal from "./components/CallBackModal";

export default {
    name: "App",
    components: {CallBackModal, AppFooter, Welcome, Products, TaskFlow, SmartElement, Automation, Crew, Contacts}
};
</script>

<style lang="scss">
@import "/assets/scss/layout.scss";
@import "/assets/scss/mobile_layout.scss";

</style>
