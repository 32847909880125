<template>
    <section id="task_flow">
        <img
                src="@/assets/images/lines_element_blue.svg"
                class="products-lines"
                data-aos="fade"
        />
        <div class="container template task_flow">
            <div class="row">
                <div class="col">
                    <h2 data-aos="fade-right">
                        Документооборот <span class="sky">Task</span> Flow
                    </h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col">
                    <div class="description" data-aos="fade-left">
                        Наш продукт решает две взаимосвязанные задачи -
                        настройка оборота документов внутри организации и
                        контроль исполнения поручений и задач, возникающих в
                        это процессе.
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                        class="col-md-4"
                        v-for="(item, index) in task_flow"
                        :key="'task_flow_feature' + index"
                        data-aos="fade-down"
                        :data-aos-delay="100 * ((index % 3) + 1)"
                >
                    <div
                            class="feature"
                            @mouseenter="item.hovered = true"
                            @mouseleave="item.hovered = false"
                    >
                        <img
                                src="@/assets/images/feature_lines.svg"
                                v-if="item.hovered"
                        />
                        <div class="title">
                            <div class="line"></div>
                            <h4>{{ item.title }}</h4>
                        </div>
                        <div class="description">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data: ()=>({
            task_flow: [
                {
                    title: "Интерфейс системы",
                    description:
                        "Простое создание задач по принципу электронного письма",
                    hovered: true,
                },
                {
                    title: "Маршруты",
                    description:
                        "Создание маршрутов любой сложности самостоятельно с 12 вариантами действий",
                    hovered: true,
                },
                {
                    title: "Типы задач",
                    description:
                        "Единичные поручения и задачи с согласованием и утверждениям по маршрутам",
                    hovered: true,
                },
                {
                    title: "Протоколы",
                    description:
                        "Работа с протоколами рабочих групп, Правления, СД и отслеживание решений по ним",
                    hovered: false,
                },
                {
                    title: "Планы мероприятий",
                    description:
                        "Работа с планами мероприятий, проектами и отслеживание задач по ним",
                    hovered: false,
                },
                {
                    title: "Контроль исполнения",
                    description:
                        "Контроль всех запущенных задач, проверка статусов исполнения",
                    hovered: false,
                },
                {
                    title: "PDF",
                    description:
                        "Генерация служебных записок и протоколов в формате PDF",
                    hovered: false,
                },
                {
                    title: "Заместитель",
                    description:
                        "Функция заместителя в период отсутствия на работе",
                    hovered: false,
                },
                {
                    title: "Уведомления",
                    description:
                        "Уведомления о задачах в системе и на электронную почту",
                    hovered: false,
                },
            ],
        })
    }
</script>
