<template>
  <section id="main">
    <b-carousel
      v-model="carousel"
      class="main-carousel"
      :interval="10000"
      style="position: absolute; top: 0; left: 0; width: 100%"
    >
      <b-carousel-slide v-for="i in 3" :key="'carousel-item-main' + i">
        <template v-slot:img>
          <div
            class="main-carousel__item"
            :style="{
              'background-image': `url(${require('@/assets/images/main-' +
                i +
                '.png')})`,
            }"
          ></div>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <img
      src="@/assets/images/lines_element.svg"
      class="lines__black"
      v-if="main_lines"
    />

    <div class="container">
      <div class="row navigation">
        <div class="col">
          <b-navbar toggleable="md">
            <b-navbar-brand href="#">
              <div class="logo">
                <div class="logo__text">
                  <span class="sky">SMART</span>
                  <span>STATE</span>
                </div>
                <div class="logo__line"></div>
              </div>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-item
                  v-for="(item, index) in nav"
                  :key="'link' + index"
                  :href="item.to"
                  class="navlink"
                  @click.prevent="navTo(item.to)"
                >
                  <div class="navlink__text">
                    {{ item.title }}
                  </div>
                  <div class="navlink__line"></div>
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </div>

      <div class="row main">
        <div class="col main-content1" v-if="carousel === 0">
          <h1 data-aos="fade-right" style="margin-bottom: 1em">
            <span class="sky">SMART</span>
            <span> ELEMENT</span>
          </h1>

          <div class="short-description">
            <div
              class="item"
              v-for="(item, index) in content_1"
              :key="index + 'main-content1.short-description'"
              :style="{ 'padding-left': `${index * 27}px` }"
              data-aos="fade-right"
              :data-aos-delay="100 * (index + 1)"
            >
              <div class="line"></div>
              <div class="text">
                {{ item }}
              </div>
            </div>
          </div>

          <div class="functions">
            <button
              class="button"
              data-aos="fade-right"
              :data-aos-delay="100 * (content_1.length + 1)"
              v-if="main_button"
            >
              <a class="link-help" href="#" @click.prevent="navTo('products')">
                Узнать подробнее
              </a>
              <div class="line"></div>
            </button>
          </div>
        </div>

        <div class="col main-content1" v-if="carousel === 1">
          <h1>
            <div data-aos="fade-right">АВТОМАТИЗАЦИЯ</div>
            <div
              style="margin-left: 10%"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <span class="sky">БИЗНЕС</span>
              <span>-ПРОЦЕССОВ</span>
            </div>
          </h1>

          <div class="short-description">
            <div
              class="item"
              v-for="(item, index) in content_2"
              :key="index + 'main-content2.short-description'"
              :style="{ 'padding-left': `${index * 27}px` }"
              data-aos="fade-right"
              :data-aos-delay="100 * (index + 1)"
            >
              <div class="text">
                {{ item }}
              </div>
            </div>
          </div>

          <div class="functions">
            <button
              class="button"
              data-aos="fade-right"
              :data-aos-delay="100 * (content_2.length + 1)"
              v-if="main_button"
            >
              <a
                class="link-help"
                href="#"
                @click.prevent="navTo('automation')"
              >
                Узнать подробнее
              </a>
              <div class="line"></div>
            </button>
          </div>
        </div>

        <div class="col main-content1" v-if="carousel === 2">
          <h1>
            <div data-aos="fade-right">РАЗРАБОТКА САЙТОВ</div>
            <div
              style="margin-left: 20%"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <span>И</span>
              <span class="sky"> ПРИЛОЖЕНИЙ</span>
            </div>
          </h1>

          <div class="short-description">
            <div
              class="item"
              v-for="(item, index) in content_3"
              :key="index + 'main-content3.short-description'"
              :style="{ 'padding-left': `${index * 27}px` }"
              data-aos="fade-right"
              :data-aos-delay="100 * (index + 1)"
            >
              <div class="text">
                {{ item }}
              </div>
            </div>
          </div>

          <div class="functions">
            <button
              class="button"
              data-aos="fade-right"
              :data-aos-delay="100 * (content_3.length + 1)"
              v-if="main_button"
            >
              <a
                class="link-help"
                href="#"
                @click.prevent="navTo('automation')"
              >
                Узнать подробнее
              </a>
              <div class="line"></div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="main-dots">
      <div
        v-for="i in 3"
        :class="{ active: i - 1 === carousel }"
        @click="carousel = i - 1"
        :key="'main-dots' + i"
      ></div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'welcome',

  methods: {
    navTo(item) {
      document.getElementById(item).scrollIntoView({ behavior: 'smooth' })
    },
  },

  watch: {
    carousel() {
      this.main_lines = false
      this.main_button = false
      setTimeout(() => {
        this.main_lines = true
        this.main_button = true
      }, 300)
    },
  },

  data: () => ({
    carousel: 0,
    main_lines: true,
    main_button: true,

    content_1: [
      'Система документооборота',
      'Информационная система',
      'Корпоративный портал',
    ],

    content_2: [
      'Разработка корпоративного',
      'программного обеспечения',
      'для нужд вашей компании на заказ',
    ],

    content_3: [
      'Разработка сайтов различной сложности',
      'Разработка мобильных приложений IOS и Android',
    ],

    nav: [
      { title: 'Главная', to: 'main' },
      { title: 'Продукты', to: 'products' },
      { title: 'Компания', to: 'crew' },
      { title: 'Контакты', to: 'contacts' },
    ],
  }),
}
</script>
